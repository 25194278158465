#loader1 {
	display: block;
	position: relative;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-top-color:#da291c;
	-webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
	 
}
#loader1:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-top-color: #da291c;
	-webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
#loader1:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-top-color: #da291c;
	-webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 @-webkit-keyframes spin {
 0% {
 -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
 -ms-transform: rotate(0deg);  /* IE 9 */
 transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
}
 100% {
 -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
 -ms-transform: rotate(360deg);  /* IE 9 */
 transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
}
}
@keyframes spin {
 0% {
 -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
 -ms-transform: rotate(0deg);  /* IE 9 */
 transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
}
 100% {
 -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
 -ms-transform: rotate(360deg);  /* IE 9 */
 transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
}
}

