.modal {
    z-index: 100;
    /*background:#1d2c38;*/
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 10px;
    border:solid 1px gray;
 /*   max-width: 500px;*/
    padding: 2rem;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}

.modal-wrapper {
    position: fixed;
    
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-close-button {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: .3;
    cursor: pointer;
    border: none;
}

button {
    font-size: .9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: .3rem 1rem;
    margin-left: .5rem;
}

.button-default {
    background: #247BA0;
    color: #fff;
}